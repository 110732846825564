<template>
  <div>
    <surround-box :width="330" title="设备设施">
      <div class="content-box">
        <div class="popbox sb">
          <span>{{ baseInfo.deviceCount }}</span>
          <span>设备(个)</span>
        </div>
        <div class="popbox yzgm">
          <span>{{ baseInfo.productNum }}</span>
          <span>养殖规模(头)</span>
        </div>
        <div class="popbox ss">
          <span>{{ baseInfo.facilityCount }}</span>
          <span>设施(个)</span>
        </div>
        <div class="popbox yztd">
          <span>{{ baseInfo.area }}</span>
          <span>养殖土地(亩)</span>
        </div>
        <div class="popbox jcd">
          <span>{{ baseInfo.authCount }}</span>
          <span>监测点(个)</span>
        </div>
        <div class="popbox yzc">
          <span>{{ baseInfo.mapCount }}</span>
          <span>养殖场(个)</span>
        </div>
      </div>
      <!-- <span class="guanbi iconfont icon-guanbi1" @click="close"></span>
    <div class="outer-page">
      <div class="base-info">
        <ul>
          <li>
            <span>{{ data.productNum }}</span
            ><br />
            <span style="font-size: 13px">养殖规模(头)</span>
          </li>
          <li>
            <span>{{ data.area }}</span
            ><br />
            <span style="font-size: 13px">养殖土地(亩)</span>
          </li>
          <li>
            <span>{{ data.deviceCount }}</span
            ><br />
            <span style="font-size: 13px">配套设备(个)</span>
          </li>
          <li>
            <span>{{ data.facilityCount }}</span
            ><br />
            <span style="font-size: 13px">配套设施(个)</span>
          </li>
          <li>
            <span>{{ data.production }}</span
            ><br />
            <span style="font-size: 13px">粪污产量(kg/d)</span>
          </li>
          <li v-for="(item, index) in staticsData" :key="index">
            <span>{{ item.content }}</span
            ><br />
            <span style="font-size: 13px">{{
              item.name + "(" + item.unit + ")"
            }}</span>
          </li>
           <li>
            <span>-</span><br />
            <span style="font-size: 13px">产能配套率</span>
          </li>
          <li>
            <span>-</span><br />
            <span style="font-size: 13px">待处理(KG)</span>
          </li>
        </ul>
      </div>
    </div> -->
    </surround-box>
    <surround-box :width="330" style="margin: 20px 0" title="产能配套率">
      <div class="cont-box">
        <progress-ball
            :height="150"
            :percent="percent"
            :width="150"
            isDrawText
        ></progress-ball>
        <div>
          <p class="percent">
            {{
              staticsData.length > 0
                  ? staticsData.find(
                  (item) => item.name.indexOf("配套产能率") > -1
              ).content + "%"
                  : ""
            }}
          </p>
          <p>配套产能率</p>
        </div>
      </div>
    </surround-box>
    <surround-box :width="330" :title="currentMonitor.remark || '设备统计数据'">
      <div class="text-right" style="position: absolute;top: 14px;right: 10px;color: white;">

        <span style="display: inline-block;font-size: 10px">{{
            currentMonitor.sensorTypeName || ''
          }}</span>
      </div>
      <div class="switch" style="display: inline-block; position: absolute;top: 14px;left: 10px">
        <Icon :color="indexType === 0 ? 'gray': 'white'" size="16" style="cursor: pointer;" type="ios-arrow-back"
              @click="switchClick('left')"/>
        <Icon :color="indexType === 2 ? 'gray' : 'white'" size="16" style="cursor: pointer;" type="ios-arrow-forward"
              @click="switchClick('right')"/>
      </div>
      <!--      <div style="color: white;position: absolute;top: 50px;left: 10px;">单位：m³</div>-->
      <!--      <div ref="chart" class="chart"></div>-->
      <div style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center">
        <div class="monitor-value-box">
          <div
              v-for="item in monitorData"
              :key="item.id"
              :class="[
                'monitor-value-item',
                currentMonitor && currentMonitor.id == item.id
                  ? 'monitor-value-item-active'
                  : '',
              ]"
              @click="() => (currentMonitor = item)"
          >
            <img :src="imgLs(item)" alt=""/>
            <div class="value">
              <p class="num">{{ item.measure }}</p>
              <p>
                {{ nameLs(item) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </surround-box>
  </div>
</template>

<script>
import surroundBox from "@/components/mapComp/surroundBox2.vue";
import progressBall from "@/components/progressBall";
import * as echarts from "echarts";

export default {
  components: {
    surroundBox,
    progressBall,
  },
  name: "",
  data() {
    return {
      data: [],
      staticsData: [],
      percent: 0,
      baseInfo: {},
      type: [1, 2, 3],
      monitorData: [],
      currentMonitor: {},
      indexType: 0,
    };
  },
  methods: {
    getList() {
      return new Promise((resolve) => {
        this.$post('statistics/useWater').then((res) => {
          this.data = res;
          resolve()
        });
      });
    },
    getBaseInfo() {
      return new Promise((resolve) => {
        this.$post(this.$api.PRODUCE_MAPINFO.STATICS).then((res) => {
          this.baseInfo = res;
          resolve()
        });
      });
    },

    getStatics() {
      this.$post(this.$api.STATICS_MANAGE.GET4, {
        pid: 8,
      }).then((res) => {
        // console.log(res);
        this.staticsData = res;
        this.percent = res.find(
            (item) => item.name.indexOf("配套产能率") > -1
        )?.content || '';
        this.initChart();
      });
    },
    initChart() {
      // let chart = echarts.init(this.$refs.chart);
      let xData = this.staticsData
          .filter((item) => item.name.indexOf("配套产能率") == -1)
          .map((el) => el.name);
      xData.push("粪污产量(kg/d)");
      let data = this.staticsData
          .filter((item) => item.name.indexOf("配套产能率") == -1)
          .map((el) => el.content);
      data.push(this.data.production);
      let option = {
        grid: {
          top: 20,
          left: 0,
          bottom: 40,
          right: 0,
        },
        xAxis: {
          data: this.data.map(r => r.years),
          axisLine: {
            show: false,
            lineStyle: {
              color: "#fff",
            },
          },
          axisTick: {
            show: false,
          },
        },
        yAxis: {
          show: false,
        },
        series: {
          type: "bar",
          data: this.data.map(r => r.num),
          barMaxWidth: 30,
          label: {
            show: true,
            position: "top",
            color: "#fff",
          },
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#947DFF",
              },
              {
                offset: 1,
                color: "#23A6F2",
              },
            ]),
          },
        },
      };
      // chart.setOption(option);
    },
    close() {
      this.$emit("on-change");
    },
    getFarmId() {
      return this.$post(this.$api.STATICTICS_MAP.PLATFORM_INFO, {
        resourceTypeId: 188
      }).then(res => {
        if (res[0]) {
          this.farmId = res[0].content
          this.getMonitor()
        }
      })
    },
    getMonitor() {
      let params = {
        farmId: this.farmId,
        sensorType: this.type[this.indexType],
      }
      this.$post(this.$api.MONITOR.TREATMENT_EFFECT_MONITOR, params).then((res) => {
        this.monitorData = res.list.slice(0, 6);
        this.currentMonitor = res.list[0];
      });
    },
    nameLs(item) {
      let unit = ''
      if (item.sensorCategoryUnit) {
        unit = '(' + item.sensorCategoryUnit + ')'
      }
      let name = item.sensorCategoryName || '--'
      return name + unit
    },
    imgLs(item) {
      if (item.imgUrl) {
        return this.getImgUrl(item.servicePath, item.imgUrl)
      } else {
        return '/imgs/empty/empty3.png'
      }
    },
    switchClick(row) {
      if (row === 'left') {
        if (this.indexType === 0) {
          return
        }
        this.indexType--
        this.getMonitor()
      } else {
        if (this.indexType === 2) {
          return
        }
        this.indexType++
        this.getMonitor()
      }
    },
  },
  mounted() {
    this.getFarmId()
    this.getBaseInfo()
    this.getList().then(() => {
      this.getStatics();
    })
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  // width: 100%;
  // height: 100%;
  // background: rgba(23, 34, 60, 0.6);
  // background-color: rgba(22, 60, 53, 0.5);
  border-radius: 4px;
  color: #fff;
  padding: 16px;
  // .title {
  //   height: 24px;
  //   line-height: 24px;
  //   text-align: center;
  //   font-size: 16px;
  // }
  .guanbi {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    padding-right: 10px;
    padding-top: 10px;
    font-size: 9px;
    color: #fff;
  }

  .base-info {
    // margin-top: 10px;
    height: 380px;
    // border: 1px solid #d8e0f3;
    border-radius: 8px;
    // padding: 20px;
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      li {
        text-align: center;
        width: 50%;
        height: 90px;
        padding-top: 16px;

        span {
          &:nth-child(1) {
            font-size: 26px;
            font-family: PingFang SC;
            color: #d8e0f3;
          }
        }
      }
    }
  }
}

.content-box {
  width: 100%;
  height: 230px;
  position: relative;

  .popbox {
    // background: radial-gradient(
    //   rgba(0, 255, 220, 0) 0%,
    //   rgba(0, 255, 220, 0.2) 70%,
    //   rgba(0, 255, 220, 0.7) 85%,
    //   rgba(0, 255, 220, 1) 100%
    // );
    background: radial-gradient(rgba(0, 255, 220, 0) 55%,
    rgba(0, 145, 255, 0.2) 70%,
    rgba(0, 145, 255, 0.7) 85%,
    rgba(0, 145, 255, 1) 100%);
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > span:first-child {
      color: #36d8ff;
    }

    > span:last-child {
      color: #fff;
    }
  }

  .sb {
    left: 10px;
    top: 6px;
    width: 80px;
    height: 80px;
    border-radius: 45px;
  }

  .yzgm {
    left: 204px;
    top: 20px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }

  .ss {
    left: 218px;
    top: 135px;
    width: 90px;
    height: 90px;
    border-radius: 50px;
  }

  .yztd {
    left: 20px;
    top: 100px;
    width: 116px;
    height: 116px;
    border-radius: 70px;
  }

  .jcd {
    left: 100px;
    top: 20px;
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }

  .yzc {
    left: 140px;
    top: 100px;
    width: 80px;
    height: 80px;
    border-radius: 40px;
  }
}

.cont-box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 20px 0;

  > div {
    flex-shrink: 0;
    flex-grow: 0;
    text-align: center;
    margin-left: 20px;

    .percent {
      font-size: 26px;
      color: #08dddd;
    }
  }
}

.chart {
  width: 100%;
  height: 180px;
}

.monitor-value-box {
  width: 100%;
  //display: flex;
  justify-content: center;
  max-height: 170px;
  align-items: center;
  overflow-y: auto;
  min-height: 170px;

  .monitor-value-item {
    width: 150px;
    height: 59.2px;
    display: flex;
    align-items: center;
    border: 1px solid #4c6c7a;
    border-radius: 4px;
    cursor: pointer;
    float: left;
    margin-right: 5px;
    margin-top: 10px;
    margin-left: 5px;

    &:after {
      clear: both;
    }

    img {
      width: 62.4px;
      height: 62.4px;
    }

    .value {
      width: 81.6px;
      height: 62.4px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;

      .num {
        font-size: 12px;
        font-weight: bold;
      }

      &:last-child {
        font-size: 10px;
      }
    }
  }

  .monitor-value-item-active {
    //border-color: #4dcbb9;
  }
}
</style>
