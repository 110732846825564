<template>
  <canvas ref="canvas" :width="width" :height="height"></canvas>
</template>

<script>
export default {
  name: "",
  props: {
    //画布宽度
    width: {
      type: Number,
      default: 250,
    },
    //画布高度
    height: {
      type: Number,
      default: 250,
    },
    //百分比
    percent: {
      type: [Number, String],
      default: 35,
    },
    //线条宽度
    lineWidth: {
      type: Number,
      default: 2,
    },
    //绝对值 波浪宽度,数越小越宽
    waveWidth: {
      type: Number,
      default: 0.018,
    },
    //波浪高度,数越大越高
    waveHeight: {
      type: Number,
      default: 18,
    },
    strokeStyle: {
      type: String,
      default: "#1080d0",
    },
    fillStyle: {
      type: String,
      default: "#1c86d1",
    },
    isDrawText: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let sY = this.height / 2;
    let r = this.height / 2;
    let cR = r - 4 * this.lineWidth;
    let axisLength = this.width;
    return {
      sX: 0,
      sY,
      r,
      cR,
      axisLength,
      speed: 0.04,
      xOffset: 0,
      ctx: null,
      nowRange: 0,
      IsdrawCircled: false,
    };
  },
  methods: {
    draw() {
      this.ctx.clearRect(0, 0, this.width, this.height);
      if (this.IsdrawCircled == false) {
        this.drawCircle();
      }
      if (this.nowRange <= this.percent) {
        this.nowRange += 1;
      }
      if (this.nowRange > this.percent) {
        this.nowRange -= 1;
      }
      if (this.percent > 0) {
        this.drawSin(this.xOffset * Math.PI * 0.4, this.fillStyle + "80");
        this.drawSin(this.xOffset, this.fillStyle);
      }
      if (this.isDrawText) {
        this.drawText();
      }

      this.xOffset += this.speed;
      requestAnimationFrame(this.draw);
    },
    drawCircle() {
      this.ctx.beginPath();
      this.ctx.strokeStyle = this.strokeStyle;
      this.ctx.arc(this.r, this.r, this.cR + 1, 0, 2 * Math.PI);
      this.ctx.stroke();
      this.ctx.beginPath();
      this.ctx.arc(this.r, this.r, this.cR, 0, 2 * Math.PI);
      this.ctx.clip();
      this.IsdrawCircled = true;
    },
    drawSin(xOffset, color) {
      this.ctx.save();
      let points = [];
      this.ctx.beginPath();
      for (
        let x = this.sX;
        x < this.sX + this.axisLength;
        x += 20 / this.axisLength
      ) {
        let y = Math.sin((-this.sX - x) * this.waveWidth + xOffset) * 0.8 + 0.1;
        let dY = this.height * (1 - this.nowRange / 100);

        points.push([x, dY + y * this.waveHeight]);
        this.ctx.lineTo(x, dY + y * this.waveHeight);
      }
      this.ctx.lineTo(this.axisLength, this.height);
      this.ctx.lineTo(this.sX, this.height);
      this.ctx.lineTo(points[0][0], points[0][1]);
      this.ctx.fillStyle = color;
      this.ctx.fill();
      this.ctx.restore();
    },

    drawText() {
      this.ctx.save();
      let size = 0.4 * this.cR;
      this.ctx.font = size + "px Microsoft Yahei";
      this.ctx.textAlign = "center";
      this.ctx.fillStyle = '#ffffff' + "80";
      this.ctx.fillText(this.nowRange + "%", this.r, this.r + size / 2);
      this.ctx.restore();
    },
  },
  mounted() {
    let canvas = this.$refs.canvas;
    this.ctx = canvas.getContext("2d");
    this.ctx.lineWidth = this.lineWidth;
    this.draw();
  },
};
</script>

<style lang="less" scoped>
</style>
