var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"surround-box",style:({ width: _vm.width + 'px' })},[_c('div',{staticClass:"first-row"},[_c('div',{staticClass:"col-1"},[_c('div',{staticClass:"left-top-corner"},[_c('div',{staticClass:"rotate-content",style:({
            background: _vm.specialColor
              ? _vm.specialColor
              : _vm.background
              ? _vm.background
              : 'rgba(0, 0, 0, 0.5)',
          })})]),_c('div',{staticClass:"col-1-bottom",style:({
          background: _vm.specialColor
            ? _vm.specialColor
            : _vm.background
            ? _vm.background
            : 'rgba(0, 0, 0, 0.5)',
        })})]),_c('div',{staticClass:"col-2",style:({
        background: _vm.specialColor
          ? _vm.specialColor
          : _vm.background
          ? _vm.background
          : 'rgba(0, 0, 0, 0.5)',
      })}),_c('div',{staticClass:"title",style:({
        background: _vm.background ? _vm.background : 'rgba(0, 0, 0, 0.5)',
        fontSize: _vm.fontSize + 'px',
      })},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',{staticClass:"right-top-corner"}),_c('div',{staticClass:"right-bottom-corner"}),_c('div',{staticClass:"left-bottom-corner"}),_c('div',{staticClass:"content-box"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }