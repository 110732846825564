<template>
  <div :style="{ width: width + 'px' }" class="surround-box">
    <div class="first-row">
      <div class="col-1">
        <div class="left-top-corner">
          <div
            class="rotate-content"
            :style="{
              background: specialColor
                ? specialColor
                : background
                ? background
                : 'rgba(0, 0, 0, 0.5)',
            }"
          ></div>
        </div>
        <div
          class="col-1-bottom"
          :style="{
            background: specialColor
              ? specialColor
              : background
              ? background
              : 'rgba(0, 0, 0, 0.5)',
          }"
        ></div>
      </div>
      <div
        class="col-2"
        :style="{
          background: specialColor
            ? specialColor
            : background
            ? background
            : 'rgba(0, 0, 0, 0.5)',
        }"
      ></div>
      <div
        class="title"
        :style="{
          background: background ? background : 'rgba(0, 0, 0, 0.5)',
          fontSize: fontSize + 'px',
        }"
      >
        {{ title }}
      </div>
    </div>
    <div class="right-top-corner"></div>
    <div class="right-bottom-corner"></div>
    <div class="left-bottom-corner"></div>
    <div class="content-box">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    width: {
      type: Number,
      default: 400,
    },
    background: String,
    fontSize: {
      type: Number,
      default: 18,
    },
    specialColor: String,
  },
};
</script>
<style lang='less' scoped>
// @border-color: #00fff7;
@border-color: rgba(0, 144, 255, 1);
@border-width: 3px;
.surround-box {
  position: relative;
  .first-row {
    width: 100%;
    display: flex;
    align-items: stretch;
    height: 40px;
    .col-1 {
      display: flex;
      flex-direction: column;
      width: 16px;
      .left-top-corner {
        width: 100%;
        height: 16px;
        flex-grow: 0;
        flex-shrink: 0;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        .rotate-content {
          width: 32px;
          height: 32px;
          border-left: @border-width solid @border-color;
          position: absolute;
          left: 6px;
          top: 0px;
          transform: rotate(45deg);
          background: rgba(0, 0, 0, 0.5);
        }
      }
      .col-1-bottom {
        flex-grow: 1;
        flex-shrink: 1;
        background: rgba(0, 0, 0, 0.5);
        border-left: @border-width solid @border-color;
      }
    }
    .col-2 {
      width: 16px;
      border-top: @border-width solid @border-color;
      background: rgba(0, 0, 0, 0.5);
    }
    .title {
      color: #fff;
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 18px;
      background: rgba(0, 0, 0, 0.5);
      padding-right: 32px;
    }
  }
  .content-box {
    width: 100%;
    // background: linear-gradient(
    //   270deg,
    //   rgba(14, 57, 51, 0.2) 0%,
    //   rgba(14, 57, 51, 0.478) 12%,
    //   rgba(14, 57, 51, 0.6) 90%,
    //   rgba(14, 57, 51, 0.8) 100%
    // );
    background: #082c3a;
  }
  .right-top-corner {
    width: 16px;
    height: 16px;
    border-top: @border-width solid @border-color;
    border-right: @border-width solid @border-color;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .right-bottom-corner {
    width: 16px;
    height: 16px;
    border-bottom: @border-width solid @border-color;
    border-right: @border-width solid @border-color;
    position: absolute;
    right: 0px;
    bottom: 0px;
    z-index: 1;
  }
  .left-bottom-corner {
    width: 16px;
    height: 16px;
    border-bottom: @border-width solid @border-color;
    border-left: @border-width solid @border-color;
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 1;
  }
}
</style>